import { Autocomplete } from "@react-google-maps/api";
import React, { useState, useEffect } from "react";
import { getSolarSystemSizeAndImage } from "../../../redux/api";
import { Oval } from "react-loader-spinner";

const CalculateSystemSize = ({
  formData,
  appearanceSettings,
  setFormData,
  // handleChange,
  handleNextStep,
  setIsNextDisabled,
}) => {
  const [autoComplete, setAutoComplete] = useState(null);
  const [isAddressSelected, setIsAddressSelected] = useState(false); // New state
  const [findingLocation, setFindingLocation] = useState(false);

  const onLoadAutoComplete = (autoComplete) => {
    setAutoComplete(autoComplete);
  };
  const onPlaceChanged = () => {
    setFindingLocation(true);
    setIsAddressSelected(true); // Set flag to true when address is selected
    if (autoComplete !== null) {
      const p = autoComplete.getPlace();
      if (!p?.geometry?.location) {
        console.error("Place object does not contain geometry or location.");
        setFindingLocation(false);
        return;
      }

      console.log("Place object: ", p);

      // Validate that the address includes a street number
      let hasStreetNumber = false;
      let zipCode = "";
      if (p.address_components) {
        for (const component of p.address_components) {
          if (component.types.includes("street_number")) {
            hasStreetNumber = true;
          }
          if (component.types.includes("postal_code")) {
            zipCode = component.long_name;
          }
        }
      }

      if (!hasStreetNumber) {
        alert(
          "Please select a complete address that includes a street number."
        );
        setIsAddressSelected(false); // Prevent progressing to the next step
        setFindingLocation(false);
        return;
      }

      const latlng = {
        lat: p.geometry.location.lat(),
        lng: p.geometry.location.lng(),
      };

      getSolarSystemSizeAndImage(
        latlng.lat,
        latlng.lng,
        formData?.avgElectricBill
      )
        .then((res) => {
          setFormData({
            ...formData,
            coordinates: latlng,
            address: p.formatted_address,
            addressZipCode: zipCode,
            estimatedRoofArea:
              parseInt(
                res?.data?.buildingInsights?.solarPotential?.wholeRoofStats
                  ?.areaMeters2
              ) * 10.764,
            systemSizeRange: res?.data?.systemSizeRange,
            savingsData: res?.data?.savingsData,
          });

          setFindingLocation(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleAddressChange = (e) => {
    setFormData({
      ...formData,
      address: e.target.value,
    });
    setIsAddressSelected(false);
  };

  useEffect(() => {
    setIsNextDisabled(!formData?.address || !isAddressSelected); // Check both address and flag
  }, [formData?.address, isAddressSelected, setIsNextDisabled]);

  return (
    <>
      <p className="text-sm font-normal text-gray-500">
        Please select the address so we can measure your roof and calculate your
        estimated system size
      </p>
      <div className="h-full w-full flex items-center justify-start">
        <div className="flex flex-col gap-y-1 w-full">
          <div className="w-full">
            <Autocomplete
              onLoad={onLoadAutoComplete}
              onPlaceChanged={onPlaceChanged}
            >
              <input
                type="text"
                name="text"
                id="search"
                className="w-full p-2 mt-1 bg-slate-100 border border-gray-300 rounded-md shadow-sm placeholder-gray-500 focus:ring-2 focus:ring-green-500 focus:border-green-500 focus:outline-none sm:text-sm"
                placeholder="Enter Address"
                value={formData?.address || ""}
                onChange={handleAddressChange}
              />
            </Autocomplete>
          </div>

          {findingLocation && (
            <>
              <div className="flex justify-center h-full">
                <div className="flex items-center gap-2">
                  <Oval
                    height={18}
                    width={18}
                    color={appearanceSettings?.primaryColour}
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor={appearanceSettings?.secondaryColour}
                    strokeWidth={4}
                    // strokeWidthSecondary={2}
                  />
                  <p>Finding location...</p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CalculateSystemSize;
