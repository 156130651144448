import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom";
import {
  baseURL,
  getBrandsOfferedByCompany,
  getCompanyById,
  getRoofingLandingPageById,
  getRelevantProjects,
  getLandingPageServicesByCompanyId,
  storePartnersPageResopnseInDatabase,
  updateContactAndZipStatus,
  getUserLocation,
} from "../../../redux/api";
import Slider from "react-slick";
import Modal from "../../../components/Modal/Modal";
import ActiveStep from "../components/ActiveStep";
import RoofArea from "../../../assets/images/RoofArea.jpg";
import RoofingCardImage from "../../../assets/images/RoofingCardImage.png";
import People1 from "../../../assets/images/People1.jpg";
import People2 from "../../../assets/images/People2.jpg";
import People3 from "../../../assets/images/People3.jpg";
import logo from "../../../assets/images/logo.png";
import { FaCircleArrowRight } from "react-icons/fa6";
import { PiMedalFill } from "react-icons/pi";
import { FaRankingStar } from "react-icons/fa6";
import { GoogleMap } from "@react-google-maps/api";
import RoofingResults from "../components/RoofingResults";
import { getFullStateName, getStateCoordinates } from "../../../utils/states";
import { formatNumber } from "../../../utils/numbers";
import { isValidZipCode } from "../../../utils/regexValidations";
import { formatPhoneNumber } from "../../../utils/phoneNumbers";

import RoofType from "../components/RoofType";
import Levels from "../components/Levels";
import RoofAge from "../components/RoofAge";
import CalculateRoofArea from "../components/CalculateRoofArea";
import VerifyHuman from "../components/VerifyHuman";
import Email from "../components/Email";
import PhoneNumber from "../components/PhoneNumber";
import ShowRoofArea from "../components/ShowRoofArea";
import RoofingProjectsSummary from "../components/RoofingProjectsSummary";
import NoServiceInZip from "../components/NoServiceInZip";
import PropertyType from "../components/PropertyType";

const roofData = [
  { roofSizeInSqFeet: "1,000", avgCost: "$4,700 - $13,250" },
  { roofSizeInSqFeet: "1,250", avgCost: "$5,875 - $16,563" },
  { roofSizeInSqFeet: "1,500", avgCost: "$7,050 - $19,875" },
  { roofSizeInSqFeet: "1,750", avgCost: "$8,225 - $23,188" },
  { roofSizeInSqFeet: "2,000", avgCost: "$9,400 - $26,500" },
  { roofSizeInSqFeet: "2,250", avgCost: "$10,575 - $29,813" },
  { roofSizeInSqFeet: "2,500", avgCost: "$11,750 - $33,125" },
  { roofSizeInSqFeet: "2,750", avgCost: "$12,925 - $36,438" },
  { roofSizeInSqFeet: "3,000", avgCost: "$14,100 - $39,750" },
  { roofSizeInSqFeet: "3,250", avgCost: "$15,275 - $43,063" },
  { roofSizeInSqFeet: "3,500", avgCost: "$16,450 - $46,375" },
  { roofSizeInSqFeet: "3,750", avgCost: "$17,625 - $49,688" },
];
const roofMaterial = [
  { materialName: "Shingle", avgCost: "$4,500 - $21,000" },
  { materialName: "Wood Shake Shingle", avgCost: "$13,500 - $25,000" },
  { materialName: "Metal", avgCost: "$20,000 - $38,000" },
  { materialName: "Tile", avgCost: "$12,000 - $30,000" },
  { materialName: "Flat roof", avgCost: "$7,500 - $25,000" },
];

const initialValues = {
  service: null,
  // serviceIcon: "",
  zipCode: "",
  roofType: "",
  level: "",
  roofAge: "",
  propertyType: "",
  address: "",
  coordinates: null,
  addressZipCode: "",
  estimatedRoofArea: "",
  roofImage: "",
  numberOfRelevantProjects: 0,
  projects: [],
  projectsCosts: {
    min: 0,
    max: 0,
    mid: 0,
  },
  firstName: "",
  lastName: "",
  email: "",
  phoneNo: "",
  partnerResponseId: "",
};

var settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const logosSettings = {
  autoplay: true,
  autoplaySpeed: 1000,
  speed: 500,
  cssEase: "linear",
  dots: false,
  infinite: true, // Set to true to loop back to the first logo
  slidesToShow: 4, // Show 4 logos at large screens
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true, // Ensure infinite scrolling on medium screens
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true, // Ensure infinite scrolling on small screens
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true, // Ensure infinite scrolling on very small screens
      },
    },
  ],
};

const options = {
  disableDefaultUI: true,
  // minZoom: 10,
  maxZoom: 14,
  mapId: "a216e88580f77d70",
  restriction: {
    latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
    strictBounds: true,
  },
  draggable: false,
};

const formatReviewCount = (count) => {
  if (count >= 1000 && count < 10000) {
    return `${(count / 1000).toFixed(1)}k+`;
  } else if (count >= 10000) {
    return `${(count / 1000).toFixed(0)}k+`;
  }
  return count;
};

const RoofingLandingPage = () => {
  const location = useLocation();
  const { id } = useParams();
  const landingPageId = new URLSearchParams(location.search)?.get(
    "landing_page_id"
  );

  const [loading, setLoading] = useState(true);
  const [landingPageDetails, setLandingPageDetails] = useState(null);
  const [showProjectsResults, setShowProjectsResults] = useState(false);
  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [mapCenter, setMapCenter] = useState(null);
  const [steps, setSteps] = useState([
    {
      id: 1,
      title: "Select your roof type",
      component: RoofType,
      status: "current",
      completed: true,
      nextButtonText: "Go to Next Step",
      prevButtonText: "Back",
    },
    {
      id: 2,
      title: "How many levels?",
      component: Levels,
      status: "upcoming",
      completed: false,
      nextButtonText: "Go to Next Step",
      prevButtonText: "Back",
    },
    {
      id: 3,
      title: "How old is your roof?",
      component: RoofAge,
      status: "upcoming",
      completed: false,
      nextButtonText: "Go to Next Step",
      prevButtonText: "Back",
    },
    {
      id: 4,
      title: "Select your property type",
      component: PropertyType,
      status: "upcoming",
      completed: false,
      nextButtonText: "Go to Next Step",
      prevButtonText: "Back",
    },
    {
      id: 5,
      title: "Calculate roof area",
      component: CalculateRoofArea,
      status: "upcoming",
      completed: false,
      nextButtonText: "Go to Next Step",
      prevButtonText: "Back",
    },
    {
      id: 6,
      title: "Estimated roof area",
      component: ShowRoofArea,
      status: "upcoming",
      completed: false,
      nextButtonText: "Go to Next Step",
      prevButtonText: "Back",
    },
    {
      id: 7,
      title: "Project Summary",
      component: RoofingProjectsSummary,
      status: "upcoming",
      completed: false,
      nextButtonText: "See Projects",
      prevButtonText: "Back",
    },
    {
      id: 8,
      title: "Enter your name (To verify you are a real person)",
      component: VerifyHuman,
      status: "upcoming",
      completed: false,
      nextButtonText: "Go to Next Step",
      prevButtonText: "Back",
    },
    {
      id: 9,
      title: "What is your email address?",
      component: Email,
      status: "upcoming",
      completed: false,
      nextButtonText: "Go to Next Step",
      prevButtonText: "Back",
    },
    {
      id: 10,
      title: "What is your phone number?",
      component: PhoneNumber,
      status: "upcoming",
      completed: false,
      nextButtonText: "Agree & See Projects",
      prevButtonText: "Back",
    },
    {
      id: 11,
      title: "No Service in Zip Code",
      message: "",
      component: NoServiceInZip,
      status: "upcoming",
      completed: false,
      nextButtonText: "Yes, Contact Me",
      prevButtonText: "No, Thanks",
    },
  ]);
  const [formData, setFormData] = useState(initialValues);
  const [company, setCompany] = useState(null);
  const [services, setServices] = useState([]);
  const [appearanceSettings, setAppearanceSettings] = useState({
    headerBGColour: "#000",
  });
  const [brandsOffered, setBrandsOffered] = useState([]);

  const [error, setError] = useState(null);

  useEffect(() => {
    // Create and inject the TrustedForm script
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src =
      (document.location.protocol === "https:" ? "https" : "http") +
      "://api.trustedform.com/trustedform.js?field=xxTrustedFormCertUrl&ping_field=xxTrustedFormPingUrl&l=" +
      new Date().getTime() +
      Math.random();

    // Append the script to the body
    const firstScript = document.getElementsByTagName("script")[0];
    firstScript.parentNode.insertBefore(script, firstScript);

    // Cleanup: Remove the script when the component unmounts
    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, []); // Empty dependency array ensures it runs only once

  useEffect(() => {
    if (id && landingPageId) {
      const fetchData = async () => {
        try {
          setLoading(true); // Set loading to true before fetching data

          const [companyData, servicesData, brandsData, landingPageData] =
            await Promise.all([
              getCompanyById(id),
              getLandingPageServicesByCompanyId(id),
              getBrandsOfferedByCompany(id),
              getRoofingLandingPageById(landingPageId),
            ]);

          // Set fetched data
          setCompany(companyData?.data);
          setServices(servicesData?.data);
          setBrandsOffered(brandsData?.data);
          const landingPage = landingPageData?.data;
          setFormData((prevFormData) => ({
            ...prevFormData,
            service: landingPage?.service,
          }));
          setLandingPageDetails(landingPage);

          // setFormData({
          //   ...formData,
          //   zipCode: landingPageData?.defaultZipCode,
          // });

          // Handle map center coordinates
          let coordinates;
          if (landingPage?.state !== "all") {
            coordinates = getStateCoordinates(landingPage?.state);
            setMapCenter(coordinates);
          } else if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                const { latitude, longitude } = position.coords;
                coordinates = { lat: latitude, lng: longitude };
                setMapCenter(coordinates);
              },
              () => {
                coordinates = { lat: 40.7128, lng: -74.006 }; // Default coordinates
                setMapCenter(coordinates);
              }
            );
          } else {
            coordinates = { lat: 40.7128, lng: -74.006 }; // Default coordinates
            setMapCenter(coordinates);
          }

          // if (navigator.geolocation) {
          //   navigator.geolocation.getCurrentPosition(
          //     (position) => {
          //       const { latitude, longitude } = position.coords;
          //       // coordinates = { lat: latitude, lng: longitude };
          //       const geocoder = new window.google.maps.Geocoder();
          //       geocoder.geocode(
          //         {
          //           location: { lat: latitude, lng: longitude },
          //         },
          //         (results, status) => {
          //           console.log("Geocoder status:", status); // Add logging
          //           console.log("Geocoder results:", results); // Add logging
          //           if (status === "OK" && results[0]) {
          //             const zipCode = results[0].address_components.find(
          //               (component) => component.types.includes("postal_code")
          //             )?.long_name;
          //             console.log("Fetched zip code:", zipCode); // Add logging
          //             if (zipCode) {
          //               setFormData((prevFormData) => ({
          //                 ...prevFormData,
          //                 zipCode,
          //               }));
          //             } else {
          //               const zipCode = landingPage?.defaultZipCode;
          //               setFormData((prevFormData) => ({
          //                 ...prevFormData,
          //                 zipCode,
          //               }));
          //             }
          //             console.log("Updated formData:", formData); // Add logging
          //           } else {
          //             console.error("Error fetching zip code:", status);
          //           }
          //         }
          //       );
          //     },
          //     (error) => {
          //       switch (error.code) {
          //         case error.PERMISSION_DENIED:
          //           const zipCode = landingPage?.defaultZipCode;
          //           setFormData((prevFormData) => ({
          //             ...prevFormData,
          //             zipCode,
          //           }));
          //           break;
          //         case error.POSITION_UNAVAILABLE:
          //           alert("Location information is unavailable.");
          //           break;
          //       }
          //     }
          //   );
          // } else {
          //   alert("Geolocation is not supported by this browser.");
          // }

          // Update appearance settings
          if (landingPage?.appearanceSettings) {
            setAppearanceSettings(landingPage.appearanceSettings);
          }
          const fetchLocation = async () => {
            try {
              const response = await getUserLocation();
              if (response?.data) {
                const { lat, lng } = response?.data?.location;
                const geocoder = new window.google.maps.Geocoder();
                geocoder.geocode(
                  { location: { lat, lng } },
                  (results, status) => {
                    if (status === "OK" && results[0]) {
                      const zipCode = results[0].address_components.find(
                        (component) => component.types.includes("postal_code")
                      )?.long_name;
                      if (zipCode) {
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          zipCode: zipCode,
                        }));
                      } else {
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          zipCode: landingPage?.defaultZipCode,
                        }));
                      }
                    } else {
                      console.error("Error fetching zip code:", status);
                    }
                  }
                );
              }
            } catch (error) {
              console.log(error);
            }
          };
          fetchLocation();
        } catch (error) {
          console.error("Error fetching data:", error);
          setError("404: Landing page not found for the specified company.");
        } finally {
          setLoading(false); // Always set loading to false after the operations are complete
        }
      };

      fetchData();
    }
  }, [id, landingPageId]);
  useEffect(() => {
    if (formData?.zipCode && isValidZipCode(formData?.zipCode)) {
      setIsNextDisabled(false);
    } else {
      setIsNextDisabled(true);
    }
  }, [formData?.zipCode, setIsNextDisabled]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        [name]: value,
      };
      // console.log("Updated formData:", updatedFormData);
      return updatedFormData;
    });
  };

  // const setMapAccordingToUserLocationOrFilter = (zoom, center) => {
  //   if (zoom && center) {
  //     setMapCenter(center);
  //     previousZoomRef.current = zoom;
  //     setMapOptions({
  //       ...mapOptions,
  //       zoom: zoom,
  //     });
  //     setIsMapLocationReady(true);
  //     // onBoundsChanged();
  //   } else {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         const { latitude, longitude } = position.coords;
  //         setMapCenter({ lat: latitude, lng: longitude });
  //         previousZoomRef.current = map.getZoom();
  //         setIsMapLocationReady(true);
  //         // onBoundsChanged();
  //       },
  //       (error) => {
  //         console.error("Error getting current location:", error);
  //       }
  //     );
  //   }
  // };

  const getRelatedProjects = async () => {
    const requestData = {
      serviceId: formData?.service,
      roofType: formData?.roofType,
      propertyType: formData?.propertyType,
      estimatedRoofArea: formData?.estimatedRoofArea,
      companyId: company?._id,
    };

    if (landingPageDetails?.state !== "all") {
      requestData.state = landingPageDetails?.state;
    }

    await getRelevantProjects(requestData)
      .then(({ data }) => {
        setFormData({
          ...formData,
          numberOfRelevantProjects: data?.projects?.length || 0,
          projects: data?.projects || [],
          projectsCosts: {
            min: data?.minCost,
            max: data?.maxCost,
            mid: data?.midCost,
          },
          // serviceIcon: data?.serviceIcon,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showProjectResults = async () => {
    const result = await storePartnersPageResopnseInDatabase(company?._id, {
      firstName: formData?.firstName,
      lastName: formData?.lastName,
      customerEmail: formData?.email,
      phoneNo: formData?.phoneNo,
      address: formData?.address,
      partnerId: company?._id,
      landingPageId: landingPageId,
      serviceId: landingPageDetails?.service,
      trustedFormUrl: document.querySelector(
        "input[name='xxTrustedFormCertUrl']"
      )?.value, // Add TrustedForm URL here
      url: window.location.href,
      queryDetails: {
        roofType: formData?.roofType,
        level: formData?.level,
        roofAge: formData?.roofAge,
        propertyType: formData?.propertyType,
        estimatedRoofArea: formData?.estimatedRoofArea,
      },
    });

    const isZipInList = landingPageDetails?.zipCodesListId?.zipCodes?.includes(
      formData?.addressZipCode
    );
    const isRoofTypeInList = landingPageDetails?.roofMaterialsList?.includes(
      formData?.roofType
    );

    if (isZipInList && isRoofTypeInList) {
      // Both checks passed
      setShowProjectsResults(true);
      setOpen(false); // Close the popup
      return true; // Signal that popup was closed
    } else {
      // Handle cases where zip code or roof type is not in the list
      let stepTitle = "";
      let stepMessage = "";

      if (!isZipInList) {
        stepTitle = "Sorry!";
        stepMessage = `<p>Unfortunately <span className="font-bold">${
          company?.name
        }</span> does not service your area. Would you like Pro-Mapper to contact you at 
        ${
          formData.phoneNo
            ? formatPhoneNumber(formData?.phoneNo)
            : formData?.email
        }
        and help connect you with a qualified partner in your area?
      </p>`;
      } else if (!isRoofTypeInList) {
        stepTitle = "Sorry!";
        stepMessage = `<p>Unfortunately <span className="font-bold">${
          company?.name
        }</span> does not work with your roof material. Would you like Pro-Mapper to contact you at 
      ${
        formData.phoneNo
          ? formatPhoneNumber(formData?.phoneNo)
          : formData?.email
      }
      and help connect you with a qualified partner in your area?
    </p>`;
      }

      // Set the steps to change the title of the last step
      setSteps((prevSteps) => {
        const updatedSteps = [...prevSteps];
        updatedSteps[10].title = stepTitle;
        updatedSteps[10].message = stepMessage;
        return updatedSteps;
      });

      // Update form data with the partner response ID for further use
      setFormData({ ...formData, partnerResponseId: result?.data?._id });

      return false; // Signal that popup remains open
    }
  };

  const handleNextStep = async () => {
    if (activeStep === 6) {
      getRelatedProjects();
    } else if (activeStep === 10) {
      await showProjectResults(); // Let showProjectResults handle popup logic
      if (!open) return; // Stop further execution if popup closes
    } else if (activeStep === 11) {
      await updateContactAndZipStatus(formData?.partnerResponseId, {
        isZipCodeInList: false,
        shouldAdminContact: true,
      });
      setFormData(initialValues);
      setOpen(false); // Close the popup at step 10
      setActiveStep(1); // Reset steps if needed
      return; // Prevent further step updates
    }

    // Update step state only if popup is not closed
    setSteps((prevSteps) => {
      const updatedSteps = [...prevSteps];
      updatedSteps[activeStep - 1].status = "completed"; // Mark current as completed
      updatedSteps[activeStep].status = "current"; // Mark next as current
      return updatedSteps;
    });
    setActiveStep((prevStep) => prevStep + 1); // Move to the next step
  };

  const handlePrevStep = async () => {
    if (activeStep === 10) {
      await updateContactAndZipStatus(formData?.partnerResponseId, {
        isZipCodeInList: false,
        shouldAdminContact: false,
      });
      setFormData(initialValues);
      setOpen(false);
      setActiveStep(1);
    }
    if (activeStep > 1) {
      setSteps((prevSteps) => {
        const updatedSteps = [...prevSteps];
        updatedSteps[activeStep - 1].status = "upcoming"; // Mark current as upcoming
        updatedSteps[activeStep - 2].status = "current"; // Mark previous as current
        return updatedSteps;
      });
      setActiveStep((prevStep) => prevStep - 1);
    }
  };
  const handleFinish = () => {
    setOpen(false); // Close the modal when Finish is clicked on the last step
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <h1>Loading...</h1>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col justify-center items-center h-screen bg-gray-100">
        <h1 className="text-4xl font-bold text-red-600 mb-4">{error}</h1>
        <p className="text-lg text-gray-700 mb-8">
          We couldn't find the landing page you were looking for. Please check
          the URL.
        </p>
      </div>
    );
  }

  return (
    <>
      <Modal
        open={open}
        setOpen={setOpen}
        steps={steps}
        setSteps={setSteps}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        initialValues={initialValues}
        formData={formData}
        appearanceSettings={appearanceSettings}
        setFormData={setFormData}
        handleChange={handleChange}
        handleNextStep={handleNextStep}
        handlePrevStep={handlePrevStep}
        handleFinish={handleFinish}
        isNextDisabled={isNextDisabled}
        showProjectResults={showProjectResults}
      >
        <ActiveStep
          steps={steps}
          activeStep={activeStep}
          formData={formData}
          company={company}
          appearanceSettings={appearanceSettings}
          setFormData={setFormData}
          handleChange={handleChange}
          handleNextStep={handleNextStep}
          setIsNextDisabled={setIsNextDisabled}
        />
      </Modal>
      <div className="">
        <header className="relative z-40 mx-auto max-w-7xl">
          {" "}
          {/* Changed absolute to relative */}
          <nav
            aria-label="Global"
            className="flex items-center justify-between p-2 lg:px-8"
          >
            <div className="flex lg:flex-1 items-center gap-2">
              <img
                alt=""
                src={
                  company?.logo ? `${baseURL}s3/images/${company?.logo}` : ""
                }
                className="h-12 md:h-20 w-auto"
              />
              {/* <span className="text-sm md:text-lg lg:text-2xl font-bold text-gray-700">
                {company?.name}
              </span> */}
            </div>
            <div className="lg:flex lg:flex-1 lg:justify-end lg:gap-6 lg:items-center">
              <div className="flex flex-col items-center">
                <span className="text-sm md:text-lg lg:text-2xl font-bold text-gray-700">
                  {company?.name}
                </span>
                <a
                  href={`tel:${formatPhoneNumber(
                    landingPageDetails?.companyPhoneNumber
                  )}`}
                  className="text-lg md:text-xl lg:text-2xl font-base text-blue-700 underline"
                >
                  {formatPhoneNumber(landingPageDetails?.companyPhoneNumber)}
                </a>
              </div>
            </div>
          </nav>
        </header>
        <div
          className="relative isolate overflow-hidden"
          style={{ background: appearanceSettings?.headerBGColour }}
        >
          {showProjectsResults ? (
            <RoofingResults
              formData={formData}
              logosSettings={logosSettings}
              brandsOffered={brandsOffered}
              company={company}
              landingPageDetails={landingPageDetails}
              appearanceSettings={appearanceSettings}
            />
          ) : (
            <>
              <svg
                aria-hidden="true"
                className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
              >
                <defs>
                  <pattern
                    x="50%"
                    y={-1}
                    id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                    width={100}
                    height={100}
                    patternUnits="userSpaceOnUse"
                  >
                    <path d="M100 200V.5M.5 .5H200" fill="none" />
                  </pattern>
                </defs>
                <svg x="50%" y={-1} className="overflow-visible fill-gray-600">
                  <path
                    d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                    strokeWidth={0}
                  />
                </svg>
                <rect
                  fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"
                  width="100%"
                  height="100%"
                  strokeWidth={0}
                />
              </svg>
              <div className="mx-auto max-w-7xl px-6 py-12 flex flex-col gap-y-8 items-start lg:grid lg:grid-cols-2 lg:items-center lg:gap-x-10 lg:px-8 lg:py-24">
                <div className="lg:flex lg:items-center lg:h-full w-full flex">
                  <div className="flex flex-col gap-y-6">
                    <h1 className="text-4xl font-bold text-white sm:text-6xl">
                      {landingPageDetails?.headerTitle
                        ? landingPageDetails?.headerTitle
                        : "AI Powered Roof Calculator"}
                    </h1>
                    <p className="text-lg font-normal text-white sm:text-xl">
                      {landingPageDetails?.headerDescription
                        ? landingPageDetails?.headerDescription
                        : "Calculate roof area and see similar projects in your local area"}
                    </p>
                    <form
                      className="md:flex md:items-center md:gap-x-4"
                      onSubmit={(e) => {
                        e.preventDefault();
                        setOpen(true);
                      }}
                    >
                      <div>
                        <input
                          id="zipCode"
                          name="zipCode"
                          type="text"
                          value={formData?.zipCode}
                          onChange={handleChange}
                          placeholder="Enter your zip"
                          className="block w-72 rounded-full border-0 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm"
                        />
                      </div>
                      <button
                        onClick={() => setOpen(true)}
                        type="button"
                        disabled={isNextDisabled}
                        className={`md:mt-0 mt-4 flex items-center gap-x-2 rounded-full px-3.5 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-400 text-nowrap`}
                        style={{
                          backgroundColor: isNextDisabled
                            ? "#ccc"
                            : appearanceSettings?.primaryColour || "#00458f",
                          cursor: isNextDisabled ? "not-allowed" : "pointer",
                        }}
                      >
                        {landingPageDetails?.buttonText
                          ? landingPageDetails?.buttonText
                          : "Find Projects"}
                        <FaCircleArrowRight className="w-5" />
                      </button>
                    </form>
                    {company?.reviewScore && company?.reviewCount && (
                      <div className="md:flex md:gap-6">
                        <div className="flex -space-x-4 rtl:space-x-reverse">
                          <img
                            className="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800"
                            src={People1}
                            alt=""
                          />
                          <img
                            className="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800"
                            src={People2}
                            alt=""
                          />
                          <img
                            className="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800"
                            src={People3}
                            alt=""
                          />
                          <a
                            className="flex items-center justify-center w-10 h-10 text-xs font-medium text-white bg-gray-700 border-2 border-white rounded-full hover:bg-gray-600 dark:border-gray-800"
                            href="#"
                          >
                            +{formatReviewCount(company?.reviewCount - 3)}
                          </a>
                        </div>
                        <div className="flex flex-col gap-y-2 md:mt-0 mt-4">
                          <div className="flex">
                            {Array.from({ length: 5 }).map((_, index) => {
                              const score = company?.reviewScore || 0;
                              const fraction = Math.max(
                                0,
                                Math.min(1, score - index)
                              ); // Determines how much of the star to fill

                              return (
                                <div
                                  key={index}
                                  className="relative w-4 h-4 me-1"
                                >
                                  {/* Empty Star */}
                                  <svg
                                    className="absolute top-0 left-0 w-full h-full"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 22 20"
                                  >
                                    <path
                                      d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"
                                      stroke="currentColor"
                                      strokeWidth="1"
                                      fill="#d1d5db"
                                    />
                                  </svg>

                                  {/* Filled Star */}
                                  <svg
                                    className="absolute top-0 left-0 w-full h-full"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 22 20"
                                    style={{
                                      clipPath: `inset(0 ${
                                        100 - fraction * 100
                                      }% 0 0)`,
                                    }} // Dynamic clipping
                                  >
                                    <path
                                      d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"
                                      fill="#facc15"
                                    />
                                  </svg>
                                </div>
                              );
                            })}
                          </div>
                          <div className="flex text-white">
                            <p className="ms-1 text-sm font-medium">{`${company?.reviewScore}/5`}</p>
                            <p className="ms-1 text-sm font-medium">out of</p>
                            <p className="ms-1 text-sm font-medium">{`${formatReviewCount(
                              company?.reviewCount
                            )} reviews`}</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="relative w-full mt-8 sm:mt-12 lg:mt-0 flex-shrink-0 flex-grow">
                  {/* <GoogleMap
                    options={options}
                    mapContainerStyle={{
                      width: "100%",
                      height: "400px",
                      borderRadius: "1rem",
                    }}
                    center={mapCenter}
                    zoom={8}
                  ></GoogleMap> */}
                  {/* Add SVG pins */}
                  <img
                    src={
                      landingPageDetails?.headerMapImage
                        ? `${baseURL}s3/images/${landingPageDetails?.headerMapImage}`
                        : ""
                    }
                    alt="Map"
                    className="w-full object-cover rounded-lg"
                  />
                  <svg
                    className="absolute"
                    style={{
                      top: "260px",
                      left: "50px",
                      fill: appearanceSettings?.primaryColour,
                    }}
                    width="32"
                    height="32"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5s2.5 1.12 2.5 2.5S13.38 11.5 12 11.5z" />
                  </svg>
                  <svg
                    className="absolute"
                    style={{
                      top: "200px",
                      left: "150px",
                      fill: appearanceSettings?.primaryColour,
                    }}
                    width="32"
                    height="32"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5s2.5 1.12 2.5 2.5S13.38 11.5 12 11.5z" />
                  </svg>
                  <svg
                    className="absolute"
                    style={{
                      top: "330px",
                      left: "200px",
                      fill: appearanceSettings?.primaryColour,
                    }}
                    width="32"
                    height="32"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5s2.5 1.12 2.5 2.5S13.38 11.5 12 11.5z" />
                  </svg>
                  {company?.launchYear && (
                    <div className="absolute bg-gray-100 h-20 rounded-md -top-5 -left-4 shadow-lg py-4 px-2 grid grid-cols-3 gap-2">
                      <div className="flex justify-center items-center rounded-full bg-red-200 w-12 h-12 col-span-1">
                        <PiMedalFill className="text-orange-600 w-6 h-6" />
                      </div>
                      <div className="col-span-2 flex flex-col">
                        <h3 className="font-extrabold text-xl">{`${
                          new Date().getFullYear() - company?.launchYear
                        }+`}</h3>
                        <p className="font-normal text-gray-500 text-sm">
                          Years Experience
                        </p>
                      </div>
                    </div>
                  )}

                  <div className="absolute bg-gray-100 rounded-md bottom-3 right-3 shadow-lg h-14 px-1 grid grid-cols-3 gap-2">
                    <div className="flex flex-col justify-center items-center col-span-1">
                      <div className="w-10 h-10 rounded-md bg-sky-500 flex justify-center items-center">
                        <FaRankingStar className="text-white w-6 h-6" />
                      </div>
                    </div>
                    <div className="col-span-2 flex flex-col justify-center">
                      <h3 className="font-bold text-xl">98%</h3>
                      <p className="font-normal text-gray-500 text-sm">
                        Satisfaction Rate
                      </p>
                    </div>
                  </div>
                  <div className="absolute top-16 right-5">
                    <div
                      className="rounded-2xl p-1"
                      style={{
                        background: `linear-gradient(to right, ${appearanceSettings?.primaryColour}, transparent)`,
                      }}
                    >
                      <div className="bg-white rounded-[calc(1.5rem-1px)]">
                        <img
                          className="w-80 h-56 object-cover border-4 border-white mx-auto rounded-2xl"
                          src={
                            landingPageDetails?.headerImage
                              ? `${baseURL}s3/images/${landingPageDetails?.headerImage}`
                              : ""
                          }
                          alt="Map"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {/* Full BG Section Roofing */}{" "}
        <div className="w-full h-full relative overflow-hidden">
          <div className="h-full flex items-center bg-gray-50">
            <div className="xl:max-w-7xl xl:mx-auto mx-4 w-full grid grid-cols-12 px-6 lg:px-0 gap-6 relative z-10">
              <div className="col-span-12 lg:col-span-6 py-12">
                <h2
                  className="text-3xl font-bold mb-4"
                  style={{ color: appearanceSettings?.headingTextColour }}
                >
                  Roofing Replacement & Installation Cost{" "}
                  {landingPageDetails?.state === "all"
                    ? ""
                    : getFullStateName(landingPageDetails?.state)}
                </h2>
                <h2
                  className="text-2xl font-bold mb-4"
                  style={{ color: appearanceSettings?.headingTextColour }}
                >
                  Last updated:{" "}
                  <span className="font-medium text-sky-500">
                    {landingPageDetails?.lastProjectCreated.split("T")[0]}
                  </span>
                </h2>
                <h2
                  className="text-xl font-bold"
                  style={{ color: appearanceSettings?.headingTextColour }}
                >
                  Normal Range:{" "}
                  <span className="font-medium text-sky-500">
                    ${formatNumber(landingPageDetails?.minNormalCost)} - $
                    {formatNumber(landingPageDetails?.maxNormalCost)}
                  </span>
                </h2>
                <p className="text-md font-normal mt-4">
                  Roof replacement and installation costs{" "}
                  {landingPageDetails?.state === "all"
                    ? ""
                    : `in ${getFullStateName(landingPageDetails?.state)}`}{" "}
                  range from{" "}
                  <span className="text-sky-500 font-semibold">
                    ${formatNumber(landingPageDetails?.minCost)} - $
                    {formatNumber(landingPageDetails?.maxCost)}
                  </span>{" "}
                  with most homeowners paying{" "}
                  <span className="text-sky-500 font-semibold">
                    ${formatNumber(landingPageDetails?.averageCost)}.
                  </span>
                </p>
                <p className="text-md font-normal">
                  The roof is one of the most crucial aspects of any home.
                  Having a quality roof installed by a reliable contractor
                  ensures your roof will remain in good shape for years to come.
                </p>
                <div>
                  <button
                    type="button"
                    onClick={() => window.scrollTo(0, 0)}
                    className="w-auto flex items-center gap-x-2 rounded-full px-4 py-3.5 text-sm font-semibold text-white shadow-sm"
                    style={{ background: appearanceSettings?.primaryColour }}
                  >
                    Calculate your roof area & see similar projects
                    <FaCircleArrowRight className="w-5" />
                  </button>
                </div>
              </div>
            </div>
            <div className="absolute top-0 right-0 w-full lg:w-1/2 h-full lg:before:absolute lg:before:inset-0 lg:before:bg-gradient-to-r lg:before:from-gray-50 lg:before:to-transparent lg:before:z-10">
              <img
                src={
                  landingPageDetails?.firstSectionBGImage
                    ? `${baseURL}s3/images/${landingPageDetails?.firstSectionBGImage}`
                    : ""
                }
                alt="Roofing"
                className="w-full h-full object-cover relative z-0 lg:opacity-100 opacity-30"
              />
            </div>
          </div>
        </div>
        {/* Full BG Section Roofing Ends */}
        <div className="xl:mx-auto xl:max-w-7xl mt-6 mx-4">
          <div className="flex flex-col justify-center gap-3">
            <h4
              className="md:text-3xl text-2xl font-bold"
              style={{ color: appearanceSettings?.headingTextColour }}
            >
              How roof size affects cost
            </h4>
            <p>
              The average roof size{" "}
              {landingPageDetails?.state === "all"
                ? ""
                : `in ${getFullStateName(landingPageDetails?.state)}`}{" "}
              is between {formatNumber(landingPageDetails?.minAverageRoofSize)}{" "}
              - {formatNumber(landingPageDetails?.maxAverageRoofSize)} sq Feet.
              The size of your roof plays a significant role in determining the
              cost to replace it. Larger roofs require more materials and labor,
              which naturally increases the overall expense. A bigger roof means
              not only additional shingles, tiles, or metal panels but also
              extended underlayment, flashing, and fasteners to ensure a secure
              installation. Labor costs also rise as roof size increases since
              the job will take longer to complete. In addition, larger roofs
              may require more safety equipment and cleanup efforts, adding to
              the total cost. Therefore, when budgeting for a roof replacement,
              understanding your roof's size can help set realistic expectations
              for the project’s expense.
            </p>

            <div className="mt-2 flow-root">
              <div className="overflow-x-auto">
                <div className="inline-block min-w-full py-2 align-middle">
                  <table className="min-w-full divide-y divide-gray-300 rounded-lg overflow-hidden">
                    <thead className="bg-gray-900 rounded-t-lg">
                      <tr className="text-white">
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-3 border-r border-gray-500"
                        >
                          Roof Size (sq ft)
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold"
                        >
                          Average Cost
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {roofData.map((data, index) => (
                        <tr
                          key={data.roofSizeInSqFeet}
                          className={`even:bg-gray-50 ${
                            index === roofData.length - 1 ? "rounded-b-lg" : ""
                          }`}
                        >
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3 border-r border-gray-300">
                            {data.roofSizeInSqFeet}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {data.avgCost}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div>
              <button
                type="button"
                onClick={() => window.scrollTo(0, 0)}
                className="w-auto flex items-center gap-x-2 rounded-full px-4 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-400"
                style={{ background: appearanceSettings?.primaryColour }}
              >
                Calculate your roof area & see similar projects
                <FaCircleArrowRight className="w-5" />
              </button>
            </div>
          </div>
          <div className="flex flex-col justify-center gap-3 mt-6">
            <h4
              className="text-3xl font-bold"
              style={{ color: appearanceSettings?.headingTextColour }}
            >
              Roof Material Options
            </h4>
            <p>
              While asphalt shingles are the most popular choice for homeowners
              due to their affordability, durability, and ease of installation,
              there are several other roofing options available. Each of these
              alternatives comes with its own unique advantages and
              considerations, allowing you to choose the best fit for your
              specific needs, style preferences, and budget.
            </p>

            <div className="mt-2 flow-root">
              <div className="overflow-x-auto">
                <div className="inline-block min-w-full py-2 align-middle">
                  <table className="min-w-full divide-y divide-gray-300 rounded-lg overflow-hidden">
                    <thead className="bg-gray-900 rounded-t-lg">
                      <tr className="text-white">
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-3 border-r border-gray-500"
                        >
                          Roof Material
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold"
                        >
                          Average Cost (for 2,500 Sq ft roof)
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {roofMaterial.map((data, index) => (
                        <tr
                          key={data.materialName}
                          className={`even:bg-gray-50 ${
                            index === roofData.length - 1 ? "rounded-b-lg" : ""
                          }`}
                        >
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3 border-r border-gray-300">
                            {data.materialName}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {data.avgCost}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div>
              <button
                type="button"
                onClick={() => window.scrollTo(0, 0)}
                className="w-auto flex items-center gap-x-2 rounded-full px-4 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-400"
                style={{ background: appearanceSettings?.primaryColour }}
              >
                Calculate your roof area & see similar projects
                <FaCircleArrowRight className="w-5" />
              </button>
            </div>
          </div>
        </div>
        <div className="xl:mx-auto xl:max-w-7xl mt-6 mx-4">
          <div className="relative">
            <div className="md:float-right md:w-[45%] md:ml-4 mb-4 md:mb-0">
              <img
                src={
                  landingPageDetails?.sectionImage
                    ? `${baseURL}s3/images/${landingPageDetails?.sectionImage}`
                    : ""
                }
                alt="Example"
                className="w-full rounded-lg object-cover"
              />
            </div>

            {/* Text content with a responsive gap */}
            <div className="space-y-3">
              <h3
                className="text-2xl font-semibold"
                style={{ color: appearanceSettings?.headingTextColour }}
              >
                Asphalt Shingles
              </h3>
              <p>
                Asphalt shingles remain the most popular roofing material for
                homeowners, and it’s easy to see why. With advancements in
                roofing technology, modern asphalt shingles are more durable,
                weather-resistant, and visually appealing than ever before. We
                take pride in offering premium asphalt shingle roof replacements
                backed by an industry-leading 50-year warranty. With unmatched
                longevity and protection, our roofs ensure your home stays safe
                and stylish for decades to come.
              </p>
              <h3
                className="text-2xl font-semibold"
                style={{ color: appearanceSettings?.headingTextColour }}
              >
                Metal Roofing
              </h3>
              <p>
                Metal roofs are becoming increasingly popular for their
                longevity, often lasting 40-70 years with minimal maintenance.
                They are highly resistant to weather elements, including wind,
                rain, and even fire. Metal roofs are also energy-efficient, as
                they reflect sunlight, reducing cooling costs in warmer months.
                However, they can be more expensive upfront and may be noisier
                during heavy rainfall unless proper insulation is added.
              </p>
              <h3
                className="text-2xl font-semibold"
                style={{ color: appearanceSettings?.headingTextColour }}
              >
                Clay and Concrete Tiles
              </h3>
              <p>
                If you’re looking for a classic and elegant look, clay or
                concrete tiles offer a unique aesthetic appeal. These roofing
                materials are highly durable, often lasting over 50 years, and
                are ideal for warmer climates as they resist heat well. While
                they add charm and longevity to a home, clay and concrete tiles
                are heavy, requiring additional structural support, and they
                generally come with a higher installation cost.
              </p>
              <h3
                className="text-2xl font-semibold"
                style={{ color: appearanceSettings?.headingTextColour }}
              >
                Wood Shingles and Shakes
              </h3>
              <p>
                For homeowners looking for a rustic, natural look, wood shingles
                or shakes may be a great choice. Made from cedar, redwood, or
                pine, wood roofs can blend beautifully with the surrounding
                environment. They are eco-friendly, energy-efficient, and offer
                moderate durability. However, wood roofing requires more
                maintenance than other materials, as it can be susceptible to
                mold, rot, and insect damage, especially in humid climates.
              </p>
              <h3
                className="text-2xl font-semibold"
                style={{ color: appearanceSettings?.headingTextColour }}
              >
                Choosing the Right Roof for Your Home
              </h3>
              <p>
                Selecting the right roofing material is about more than just
                aesthetics—it’s about finding a solution that suits your
                lifestyle, budget, and long-term goals. Asphalt shingles may be
                a standard choice, but exploring these alternatives could lead
                you to a roof that better meets your needs and enhances your
                home’s value and curb appeal.
              </p>
            </div>
          </div>
        </div>
        <div className="xl:mx-auto xl:max-w-7xl mt-10 mx-4">
          <div className="relative">
            {/* Text content with a responsive gap */}
            <div className="space-y-3">
              <h3
                className="md:text-3xl text-2xl font-bold"
                style={{ color: appearanceSettings?.headingTextColour }}
              >
                Boosting Property Value with a New Roof Installation
              </h3>
              <p>
                When it comes to home upgrades that increase property value,
                installing a new roof is one of the most impactful investments.
                A high-quality roof not only enhances the look of your home but
                also provides prospective buyers with peace of mind, knowing
                that they won’t face the added expense and hassle of roof
                repairs or replacements in the near future. This reassurance
                often translates into a higher offer price, faster sale, and
                increased interest in the property.
              </p>
              <h3
                className="text-2xl font-semibold"
                style={{ color: appearanceSettings?.headingTextColour }}
              >
                Why a New Roof Attracts Buyers
              </h3>
              <p>
                For many homebuyers, a home with a newly installed, quality roof
                signals security and long-term savings. Buyers understand that
                the roof is one of the most critical elements in protecting a
                home against the elements, including wind, rain, and sun
                exposure. When a roof is newly installed, it assures them that
                their investment is safeguarded for many years to come. Knowing
                they’re covered from potential repair costs or roof-related
                problems, buyers are often willing to pay a premium for a
                property with a high-quality roof.
              </p>
              <h3
                className="text-2xl font-semibold"
                style={{ color: appearanceSettings?.headingTextColour }}
              >
                Enhancing Curb Appeal
              </h3>
              <p>
                The roof is one of the first things potential buyers see when
                they approach a home, making it an essential element of curb
                appeal. An updated, well-maintained roof adds a fresh, clean
                look to your property, giving it a polished and well-cared-for
                appearance. Whether it’s the classic look of asphalt shingles,
                the sleek appeal of metal roofing, or the distinctive charm of
                slate or tile, a new roof can significantly enhance your home’s
                exterior appeal, making it more enticing to prospective buyers.
              </p>
              <h3
                className="text-2xl font-semibold"
                style={{ color: appearanceSettings?.headingTextColour }}
              >
                Increasing Energy Efficiency
              </h3>
              <p>
                Many modern roofing materials are designed to improve a home’s
                energy efficiency by reflecting sunlight and reducing heat
                absorption. This can lower cooling costs, which is an attractive
                selling point for buyers focused on long-term savings and
                environmental impact. A new roof with energy-efficient materials
                can make your home more appealing, especially in warmer climates
                where energy savings are particularly noticeable.
              </p>
              <div>
                <div className="my-4 grid gap-3 md:gap-6 grid-cols-8">
                  {/* Left-side image covering 60% width on medium and larger screens */}
                  <div className="col-span-5 h-72 md:h-[560px]">
                    <img
                      src={
                        landingPageDetails?.leftCollageImage
                          ? `${baseURL}s3/images/${landingPageDetails?.leftCollageImage}`
                          : ""
                      }
                      alt="Left"
                      className="w-full h-full object-cover rounded-lg"
                    />
                  </div>

                  {/* Right-side images covering 40% width on medium and larger screens */}
                  <div className="col-span-3 grid grid-rows-2 gap-3 md:gap-6 h-72 md:h-[560px]">
                    <img
                      src={
                        landingPageDetails?.rightTopCollageImage
                          ? `${baseURL}s3/images/${landingPageDetails?.rightTopCollageImage}`
                          : ""
                      }
                      alt="Top Right"
                      className="w-full h-full object-cover rounded-lg"
                    />
                    <img
                      src={
                        landingPageDetails?.rightBottomCollageImage
                          ? `${baseURL}s3/images/${landingPageDetails?.rightBottomCollageImage}`
                          : ""
                      }
                      alt="Bottom Right"
                      className="w-full h-full object-cover rounded-lg"
                    />
                  </div>
                </div>
              </div>
              <h3
                className="text-2xl font-semibold"
                style={{ color: appearanceSettings?.headingTextColour }}
              >
                Added Durability and Warranty Benefits
              </h3>
              <p>
                Most new roofs come with warranties that cover potential issues
                for many years, providing an added layer of assurance to future
                buyers. Knowing that the roof is still under warranty reduces
                the risk of unexpected expenses, which is an attractive selling
                point. Additionally, a new roof using durable, high-quality
                materials is likely to withstand weather and environmental
                stresses better than an aging roof, adding further value.
              </p>
              <h3
                className="text-2xl font-semibold"
                style={{ color: appearanceSettings?.headingTextColour }}
              >
                A Smart Investment for a Higher Return
              </h3>
              <p>
                Ultimately, a new roof is not only an investment in the
                longevity of your home but also in its market value. Studies
                have shown that the ROI (Return on Investment) for a new roof
                can be substantial, with a high percentage of the installation
                cost being recouped at the time of sale. For homeowners
                considering selling, upgrading the roof can be one of the
                smartest improvements, helping your home stand out in a
                competitive market and ensuring you maximize the value of your
                property.
              </p>
            </div>
          </div>
        </div>
        {/* <div className="xl:mx-auto xl:max-w-7xl mt-10 mx-4">
          <div className="space-y-3">
            <h3
              className="md:text-3xl text-2xl font-bold"
              style={{ color: appearanceSettings?.headingTextColour }}
            >
              Insurance Coverage for Roof Replacement Due to Damage
            </h3>
            <p>
              Homeowners insurance often covers the cost of replacing a roof if
              the damage results from specific causes listed in your policy,
              such as storms, hail, wind, or fire. While insurance policies
              vary, coverage typically applies to “sudden and accidental”
              damages, not general wear and tear over time. If your roof has
              been impacted by severe weather or another covered event, it’s
              worth exploring your insurance policy to see if you qualify for
              financial assistance on repairs or a full roof replacement. This
              can significantly reduce out-of-pocket costs, allowing you to
              restore your roof and protect your home without bearing the entire
              expense yourself.
            </p>
            <h3
              className="text-2xl font-semibold"
              style={{ color: appearanceSettings?.headingTextColour }}
            >
              Why You Should Consult a Roofing Professional
            </h3>
            <p>
              Navigating an insurance claim for roof damage can be complex,
              especially when understanding what’s covered, gathering necessary
              documentation, and ensuring all damage is accounted for in the
              claim. We recommend asking a local roofing professional to inspect
              your roof thoroughly and assess any visible and underlying damage.
              <br />
              <br />
              These experts can provide you with an accurate evaluation and
              assist you throughout the claims process, ensuring that you have
              the proper evidence and paperwork to submit to your insurance
              company. Having a knowledgeable professional by your side can
              streamline the process, maximize your claim potential, and
              ultimately help you get the repairs or replacement you need to
              secure your home.
            </p>
          </div>
        </div>
        <div className="xl:mx-auto xl:max-w-7xl mt-10 mx-4">
          <div className="space-y-3">
            <h3
              className="md:text-3xl text-2xl font-bold"
              style={{ color: appearanceSettings?.headingTextColour }}
            >
              Lower Insurance Premiums with a High-Quality Roof Installation
            </h3>
            <p>
              Investing in a durable, high-quality roof can do more than just
              protect your home; it may also lead to lower insurance premiums.
              Insurance companies assess the risk of potential claims when
              determining premiums, and a well-installed, resilient roof can
              reduce that risk significantly.
              <br />
              <br /> A roof that can withstand severe weather, resist leaks, and
              handle impacts effectively lowers the chances of costly damages,
              which means insurers are less likely to face a payout for repairs
              or replacements. As a result, many insurance providers offer lower
              premiums to homeowners with newer, quality roofs that demonstrate
              a reduced likelihood of damage.
            </p>
            <h3
              className="text-2xl font-semibold"
              style={{ color: appearanceSettings?.headingTextColour }}
            >
              Choosing Materials and Professional Installation for Maximum
              Savings
            </h3>
            <p>
              By selecting materials known for their durability—such as metal,
              slate, or impact-resistant shingles—you enhance the roof’s
              resilience against potential hazards, which insurance companies
              recognize as a positive safety factor. Additionally, opting for
              professional installation further ensures the roof’s reliability,
              as properly installed roofs are less prone to issues from poor
              workmanship.
              <br />
              <br /> Some insurers may even offer discounts based on the
              specific roofing material or installation quality. Not only does
              this lower your premium costs, but it also gives you peace of mind
              knowing your home is better protected. Over time, the savings on
              insurance premiums can add up, helping to offset the initial
              investment in a quality roof.
            </p>
          </div>
        </div> */}
        <div className="xl:mx-auto xl:max-w-7xl mt-10 mx-4">
          <div className="space-y-3">
            <h3
              className="md:text-3xl text-2xl font-bold"
              style={{ color: appearanceSettings?.headingTextColour }}
            >
              Understanding Roof Lifespan: How Long Will Your Roof Last?
            </h3>
            <p>
              The lifespan of a roof varies significantly based on factors like
              roofing material, weather conditions, and maintenance efforts.
              While most roofs will last between 20 and 70 years, each type of
              roofing material has its own expected longevity. Asphalt shingle
              roofs, one of the most common choices, typically last between 20
              to 30 years, depending on the quality of materials used and local
              climate conditions.
              <br />
              <br />
              These roofs provide affordability and reliable durability, though
              they may require more frequent replacements than other materials.
              On the other hand, higher-end materials like tile and slate are
              known for their exceptional durability and aesthetic appeal, often
              lasting up to 50 years with proper care. This makes them an ideal
              choice for homeowners looking for a longer-term solution that also
              adds to the home’s curb appeal.
              <br />
              <br /> For those seeking a particularly long-lasting roof, metal
              roofing is an excellent option, with a potential lifespan of up to
              70 years. Metal roofs are highly resistant to weather elements,
              including heavy rain, wind, and even fire, making them one of the
              most durable roofing choices available. Additionally, metal roofs
              often require minimal maintenance, as they are less susceptible to
              issues like rot, insect damage, and mold growth.
              <br />
              <br /> When combined with modern finishes that prevent rusting,
              metal roofs can maintain their appearance and protective qualities
              for decades. By choosing the right material and committing to
              routine inspections and maintenance, you can ensure your roof not
              only meets but potentially exceeds its expected lifespan,
              providing long-term protection and value for your home.
            </p>
          </div>
        </div>
        {/* <div className="xl:mx-auto xl:max-w-7xl mt-10 mx-4">
          <div className="py-8 px-4 bg-gray-100 rounded-lg">
            <h3
              className="text-3xl font-semibold"
              style={{ color: appearanceSettings?.headingTextColour }}
            >
              Get In Touch
            </h3>
            <div className="grid grid-cols-10 gap-6 mt-4">
              <div className="col-span-10 md:col-span-3 p-4 bg-orange-200 rounded-lg">
                <div>
                  <form>
                    <div className="flex flex-col gap-y-4 justify-center">
                      <div>
                        <input
                          id="firstName"
                          name="firstName"
                          type="text"
                          // value={formData?.firstName}
                          // onChange={handleChange}
                          placeholder="First Name"
                          className="block w-full rounded-md border-0 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-400 sm:text-sm bg-yellow-50"
                        />
                      </div>
                      <div>
                        <input
                          id="lastName"
                          name="lastName"
                          type="text"
                          // value={formData?.lastName}
                          // onChange={handleChange}
                          placeholder="Last Name"
                          className="block w-full rounded-md border-0 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-400 sm:text-sm bg-yellow-50"
                        />
                      </div>
                      <div>
                        <input
                          id="address"
                          name="address"
                          type="text"
                          // value={formData?.address}
                          // onChange={handleChange}
                          placeholder="Address"
                          className="block w-full rounded-md border-0 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-400 sm:text-sm bg-yellow-50"
                        />
                      </div>
                      <div>
                        <input
                          id="phoneNo"
                          name="phoneNo"
                          type="text"
                          // value={formData?.phoneNo}
                          // onChange={handleChange}
                          placeholder="Phone Number"
                          className="block w-full rounded-md border-0 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-400 sm:text-sm bg-yellow-50"
                        />
                      </div>
                      <div>
                        <input
                          id="email"
                          name="email"
                          type="text"
                          // value={formData?.email}
                          // onChange={handleChange}
                          placeholder="Email"
                          className="block w-full rounded-md border-0 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-400 sm:text-sm bg-yellow-50"
                        />
                      </div>
                      <div>
                        <textarea
                          id="Message"
                          name="Message"
                          placeholder="Message"
                          className="block w-full h-32 resize-none rounded-md border-0 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-400 sm:text-sm bg-yellow-50 overflow-y-auto"
                        />
                      </div>
                      <button
                        type="button"
                        className="w-full flex items-center justify-between gap-x-2 rounded-full px-4 py-3.5 text-sm font-semibold text-white shadow-sm cursor-pointer"
                        style={{
                          background: appearanceSettings?.secondaryColour,
                        }}
                      >
                        Submit
                        <FaCircleArrowRight className="w-5" />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-span-10 md:col-span-7 h-full">
                <div className="rounded-lg">
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={8}
                    onLoad={(map) => setMap(map)}
                    options={options}
                    // ref={mapRef}
                  ></GoogleMap>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="xl:mx-auto xl:max-w-7xl mt-10 mx-4 py-2 md:py-5 lg:py-10">
          <div className="flex justify-between">
            <h2
              className="md:text-3xl text-2xl font-bold mb-3"
              style={{ color: appearanceSettings?.headingTextColour }}
            >
              The Services We Provide
            </h2>
          </div>

          <div className="overflow-hidden mt-5 slider-container">
            <Slider {...settings}>
              {services?.map((service, index) => (
                <div key={index} className="flex justify-center">
                  <div className="mx-2 lg:col-span-4 md:col-span-6 col-span-12 h-full flex">
                    <div
                      className="rounded-lg bg-gradient-to-t from-sky-600 via-transparent to-transparent p-[3px] h-full w-full"
                      style={{
                        boxShadow: "0px 4px 34px 0px #0000000A",
                        background: `linear-gradient(to top, ${appearanceSettings?.secondaryColour}, transparent 50%)`,
                      }}
                    >
                      <div
                        className="w-full rounded-lg relative bg-white flex flex-col h-full min-h-[400px]"
                        style={{
                          boxShadow: "0px 4px 34px 0px #0000000A",
                        }}
                      >
                        {/* Image at the top with fixed height */}
                        <div className="w-full h-48 flex-shrink-0">
                          {" "}
                          {/* Ensures image height is consistent */}
                          <img
                            className="w-full h-full object-cover rounded-t-lg"
                            src={
                              service?.serviceId?.name === "Roofing"
                                ? RoofingCardImage
                                : RoofArea
                            }
                            alt={service?.serviceId?.name}
                          />
                        </div>

                        {/* Service name below the image */}
                        <div className="px-4 py-2">
                          <h5 className="text-lg font-bold text-gray-900">
                            {service?.serviceId?.name}
                          </h5>
                        </div>

                        {/* List of sub-services */}
                        <div className="px-4 py-2 flex flex-col items-start space-y-2 flex-grow">
                          <ul className="list-disc ml-5">
                            {service?.workTypes?.map((workType) => (
                              <li
                                key={workType?._id}
                                className="text-base text-gray-700"
                              >
                                {workType?.name}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <div className="max-w-7xl mx-auto py-3 md:py-5 lg:py-10" id="logos">
          <h2 className="text-3xl text-center font-semibold mb-3">
            Trusted Brands
          </h2>

          {/* Slider Component for logos */}
          <div className="overflow-hidden mt-5 slider-container">
            <Slider {...logosSettings}>
              {brandsOffered?.map((brand, index) => (
                <div key={index} className="flex justify-center items-center">
                  {" "}
                  {/* Ensures vertical centering */}
                  <div className="mx-4 flex items-center justify-center">
                    <div className="p-4 rounded-lg transform transition-transform hover:scale-105 flex items-center justify-center">
                      {/* Image Section */}
                      <div className="w-auto max-h-28 flex items-center justify-center">
                        {brand?.logo ? (
                          <img
                            className="object-contain max-h-28 max-w-full"
                            src={`${baseURL}s3/images/${brand?.logo}`}
                            alt={brand?.name}
                          />
                        ) : (
                          <div className="w-24 h-24 bg-gray-200 rounded-full flex items-center justify-center">
                            <span className="text-xl font-bold text-gray-500">
                              {brand?.name
                                ? brand.name
                                    .split(" ")
                                    .map((word) => word[0])
                                    .join("")
                                    .toUpperCase()
                                : "N/A"}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <footer style={{ backgroundColor: appearanceSettings?.headerBGColour }}>
          <div className="mx-auto max-w-7xl px-6 py-12 flex flex-col lg:flex-row justify-between items-center lg:px-8 gap-x-4">
            {/* Logo Section */}
            <div className="order-2 lg:order-2 flex flex-col items-center lg:items-start space-y-4">
              <div className="bg-white px-2 py-1 rounded-md flex items-center">
                <p className="px-2 lg:px-3 text-gray-900 text-sm font-medium">
                  Powered by
                </p>
                <img src={logo} alt="Logo" className="h-6" />
              </div>
              <div className="flex space-x-4">
                <Link
                  to="/terms-and-conditions"
                  className="text-sm font-light hover:underline text-white"
                  // style={{ color: appearanceSettings?.paragraphTextColour }}
                >
                  Terms and Conditions
                </Link>
                <Link
                  to="/privacy-policy"
                  className="text-sm font-light hover:underline text-white"
                  // style={{ color: appearanceSettings?.paragraphTextColour }}
                >
                  Privacy Policy
                </Link>
              </div>
            </div>

            {/* Company Logo */}

            <a href="#" className="flex gap-2 items-center">
              <img
                alt={company?.name || "Company Logo"}
                src={
                  company?.logo ? `${baseURL}s3/images/${company?.logo}` : ""
                }
                className="h-16 w-auto"
              />
              <span className="text-white text-2xl font-semibold">
                {company?.name}
              </span>
            </a>
          </div>

          <hr className="border-gray-500" />

          {/* Footer Bottom */}
          <div className="py-4 text-center">
            <span
              className="font-light text-sm text-white"
              // style={{ color: appearanceSettings?.paragraphTextColour }}
            >
              © 2024 Pro-Mapper. All rights reserved.
            </span>
          </div>
        </footer>
      </div>
    </>
  );
};

export default RoofingLandingPage;
