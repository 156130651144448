import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom";
import {
  baseURL,
  getBrandsOfferedByCompany,
  getCompanyById,
  getSolarLandingPageById,
  getLandingPageServicesByCompanyId,
} from "../../../redux/api";
import Slider from "react-slick";
import RoofArea from "../../../assets/images/RoofArea.webp";
import RoofingCardImage from "../../../assets/images/RoofingCardImage.webp";
import logo from "../../../assets/images/logo.png";
import { FaCircleArrowRight } from "react-icons/fa6";
import { getFullStateName } from "../../../utils/states";
import { formatNumber, formatSystemSize } from "../../../utils/numbers";
import { formatPhoneNumber } from "../../../utils/phoneNumbers";
import { useInView } from "react-intersection-observer";
import SolarLandingPageHeroSection from "../LandingPageHeroSection/SolarLandingPageHeroSection/SolarLandingPageHeroSection";

const roofData = [
  {
    systemSizeInkW: "3",
    avgCost: "$6900 - $12000",
    costAfterTaxCredit: "$4830 - $8400",
  },
  {
    systemSizeInkW: "3.5",
    avgCost: "$8050 - $14000",
    costAfterTaxCredit: "$5635 - $9800",
  },
  {
    systemSizeInkW: "4",
    avgCost: "$9200 - $16000",
    costAfterTaxCredit: "$6440 - $11200",
  },
  {
    systemSizeInkW: "4.5",
    avgCost: "$10350 - $18000",
    costAfterTaxCredit: "$7245 - $12600",
  },
  {
    systemSizeInkW: "5",
    avgCost: "$11500 - $20000",
    costAfterTaxCredit: "$8050 - $14000",
  },
  {
    systemSizeInkW: "5.5",
    avgCost: "$12650 - $22000",
    costAfterTaxCredit: "$8855 - $15400",
  },
  {
    systemSizeInkW: "6",
    avgCost: "$13800 - $24000",
    costAfterTaxCredit: "$9660 - $16800",
  },
  {
    systemSizeInkW: "6.5",
    avgCost: "$14950 - $26000",
    costAfterTaxCredit: "$10465 - $18200",
  },
  {
    systemSizeInkW: "7",
    avgCost: "$16100 - $28000",
    costAfterTaxCredit: "$11270 - $19600",
  },
  {
    systemSizeInkW: "7.5",
    avgCost: "$17250 - $30000",
    costAfterTaxCredit: "$12075 - $21000",
  },
  {
    systemSizeInkW: "8",
    avgCost: "$18400 - $32000",
    costAfterTaxCredit: "$12880 - $22400",
  },
  {
    systemSizeInkW: "8.5",
    avgCost: "$19550 - $34000",
    costAfterTaxCredit: "$13685 - $23800",
  },
  {
    systemSizeInkW: "9",
    avgCost: "$20700 - $36000",
    costAfterTaxCredit: "$14490 - $25200",
  },
  {
    systemSizeInkW: "9.5",
    avgCost: "$21850 - $38000",
    costAfterTaxCredit: "$15295 - $26600",
  },
  {
    systemSizeInkW: "10",
    avgCost: "$23000 - $40000",
    costAfterTaxCredit: "$16100 - $28000",
  },
  {
    systemSizeInkW: "10.5",
    avgCost: "$24150 - $42000",
    costAfterTaxCredit: "$16905 - $29400",
  },
  {
    systemSizeInkW: "11",
    avgCost: "$25300 - $44000",
    costAfterTaxCredit: "$17710 - $30800",
  },
  {
    systemSizeInkW: "11.5",
    avgCost: "$26450 - $46000",
    costAfterTaxCredit: "$18515 - $32200",
  },
  {
    systemSizeInkW: "12",
    avgCost: "$27600 - $48000",
    costAfterTaxCredit: "$19320 - $33600",
  },
  {
    systemSizeInkW: "12.5",
    avgCost: "$28750 - $50000",
    costAfterTaxCredit: "$20125 - $35000",
  },
  {
    systemSizeInkW: "13",
    avgCost: "$29900 - $52000",
    costAfterTaxCredit: "$20930 - $36400",
  },
  {
    systemSizeInkW: "13.5",
    avgCost: "$31050 - $54000",
    costAfterTaxCredit: "$21735 - $37800",
  },
  {
    systemSizeInkW: "14",
    avgCost: "$32200 - $56000",
    costAfterTaxCredit: "$22540 - $39200",
  },
  {
    systemSizeInkW: "14.5",
    avgCost: "$33350 - $58000",
    costAfterTaxCredit: "$23345 - $40600",
  },
  {
    systemSizeInkW: "15",
    avgCost: "$34500 - $60000",
    costAfterTaxCredit: "$24150 - $42000",
  },
];

var settings = {
  lazyLoad: "progressive",
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const logosSettings = {
  autoplay: true,
  autoplaySpeed: 1000,
  speed: 500,
  cssEase: "linear",
  dots: false,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    },
  ],
};

const SolarLandingPage = () => {
  const location = useLocation();
  const { id } = useParams();
  const landingPageId = new URLSearchParams(location.search)?.get(
    "landing_page_id"
  );
  const { version } = useParams();
  const [loading, setLoading] = useState(true);
  const [landingPageDetails, setLandingPageDetails] = useState(null);
  const [company, setCompany] = useState(null);
  const [services, setServices] = useState([]);
  const [appearanceSettings, setAppearanceSettings] = useState({
    headerBGColour: "#000",
  });
  const [brandsOffered, setBrandsOffered] = useState([]);

  const [error, setError] = useState(null);
  // const { ref: logosRef, inView: logosInView } = useInView({
  //   triggerOnce: true,
  //   threshold: 0.1,
  // });

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id && landingPageId) {
          const [companyRes, servicesRes, landingPageRes] = await Promise.all([
            getCompanyById(id),
            getLandingPageServicesByCompanyId(id),
            getSolarLandingPageById(landingPageId),
          ]);

          setCompany(companyRes.data);
          setServices(servicesRes?.data);

          const landingPageData = landingPageRes.data;
          setLandingPageDetails(landingPageData);

          setAppearanceSettings(landingPageData?.appearanceSettings || {});
        }
      } catch (error) {
        setError({
          heading: "404: Landing page not found for the specified company.",
          message:
            "We couldn't find the landing page you were looking for. Please check the URL and try again.",
        });
      } finally {
        setLoading(false);
      }
    };

    // fetchLocation();
    fetchData();
  }, [id, landingPageId]);

  // console.log("Landing Page Details", landingPageDetails);
  useEffect(() => {
    const fetchBrands = async () => {
      if (id) {
        const brandsRes = await getBrandsOfferedByCompany(id);
        setBrandsOffered(brandsRes?.data);
      }
    };

    fetchBrands();
  }, [id]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <h1>Loading...</h1>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col justify-center items-center h-screen bg-gray-100">
        <h1 className="text-4xl font-bold text-red-600 mb-4">
          {error?.heading}
        </h1>
        <p className="text-lg text-gray-700 mb-8">{error?.message}</p>
      </div>
    );
  }

  return (
    <>
      <header className="relative z-50 mx-auto max-w-7xl">
        {" "}
        <nav
          aria-label="Global"
          className="flex items-center justify-between p-2 lg:px-8"
        >
          <div className="flex lg:flex-1 items-center gap-2">
            <img
              alt=""
              src={company?.logo ? `${baseURL}s3/images/${company?.logo}` : ""}
              className="h-12 md:h-20 w-auto"
            />
            {/* Don't uncomment me */}
            {/* <span className="text-sm md:text-lg lg:text-2xl font-bold text-gray-700">
                {company?.name}
              </span> */}
            {/* Don't uncomment me ends */}
          </div>
          <div className="lg:flex lg:flex-1 lg:justify-end lg:gap-6 lg:items-center">
            <div className="flex flex-col items-center">
              <span className="text-sm md:text-lg lg:text-2xl font-bold text-gray-700">
                {company?.name}
              </span>
              <a
                href={`tel:${formatPhoneNumber(
                  landingPageDetails?.companyPhoneNumber
                )}`}
                className="text-lg md:text-xl lg:text-2xl font-base text-blue-700 underline"
              >
                {formatPhoneNumber(landingPageDetails?.companyPhoneNumber)}
              </a>
            </div>
          </div>
        </nav>
      </header>

      <SolarLandingPageHeroSection />
      {/* Full BG Section Solar*/}
      <div className="w-full h-full relative overflow-hidden">
        <div className="h-full flex items-center bg-gray-50">
          <div className="xl:max-w-7xl xl:mx-auto mx-4 w-full grid grid-cols-12 px-6 lg:px-0 gap-6 relative z-10">
            <div className="col-span-12 lg:col-span-6 py-12">
              <h2
                className="text-3xl font-bold mb-4"
                style={{ color: appearanceSettings?.headingTextColour }}
              >
                Solar System Installation Cost Data{" "}
                {landingPageDetails?.state === "all"
                  ? ""
                  : getFullStateName(landingPageDetails?.state)}
              </h2>
              <h2
                className="text-2xl font-bold mb-4"
                style={{ color: appearanceSettings?.headingTextColour }}
              >
                Last updated:{" "}
                <span className="font-medium text-sky-500">
                  {landingPageDetails?.lastProjectCreated.split("T")[0]}
                </span>
              </h2>
              <h2
                className="text-lg font-bold"
                style={{ color: appearanceSettings?.headingTextColour }}
              >
                System Sizes:{" "}
                <span className="font-medium text-sky-500">
                  {`${formatSystemSize(
                    landingPageDetails?.minAverageSolarSystemSize
                  )}kW - ${formatSystemSize(
                    landingPageDetails?.maxAverageSolarSystemSize
                  )}kW`}
                </span>
              </h2>
              <h2
                className="text-lg font-bold"
                style={{ color: appearanceSettings?.headingTextColour }}
              >
                Normal Range:{" "}
                <span className="font-medium text-sky-500">
                  ${formatNumber(landingPageDetails?.minNormalCost)} - $
                  {formatNumber(landingPageDetails?.maxNormalCost)}
                </span>
              </h2>
              <p className="text-md font-normal mt-4">
                Solar System costs{" "}
                {landingPageDetails?.state === "all"
                  ? ""
                  : `in ${getFullStateName(landingPageDetails?.state)}`}{" "}
                range from{" "}
                <span className="text-sky-500 font-semibold">
                  ${formatNumber(landingPageDetails?.minCost)} - $
                  {formatNumber(landingPageDetails?.maxCost)}
                </span>{" "}
                for cash purchases and{" "}
                <span className="text-sky-500 font-semibold">
                  ${formatNumber(landingPageDetails?.minMonthlyPayment)} - $
                  {formatNumber(landingPageDetails?.maxMonthlyPayment)}
                </span>{" "}
                per month for financed systems. With most homeowners paying{" "}
                <span className="text-sky-500 font-semibold">
                  ${formatNumber(landingPageDetails?.averageCost)}
                </span>
                (after tax credit).
              </p>
              <p className="text-md font-normal">
                A solar installation requires extensive electrical and roof
                workmanship. Having a quality solar system, installed by a
                quality contractor ensures your system will remain in good
                working order and provide substantial returns for many years to
                come.
              </p>
              <div>
                <button
                  type="button"
                  onClick={() => window.scrollTo(0, 0)}
                  className="w-auto flex items-center gap-x-2 rounded-full px-4 py-3.5 mt-2 text-sm font-semibold text-white shadow-sm"
                  style={{ background: appearanceSettings?.primaryColour }}
                >
                  Calculate what size system you need & see similar projects
                  <FaCircleArrowRight className="w-5" />
                </button>
              </div>
            </div>
          </div>

          <div className="absolute top-0 right-0 w-full lg:w-1/2 h-full lg:before:absolute lg:before:inset-0 lg:before:bg-gradient-to-r lg:before:from-gray-50 lg:before:to-transparent lg:before:z-10">
            <img
              src={
                landingPageDetails?.firstSectionBGImage
                  ? `${baseURL}s3/images/${landingPageDetails?.firstSectionBGImage}`
                  : ""
              }
              alt="Solar System"
              className="w-full h-full object-cover relative z-0 lg:opacity-100 opacity-30"
            />
          </div>
        </div>
      </div>

      <div className="xl:mx-auto xl:max-w-7xl mt-6 mx-4">
        <div className="flex flex-col justify-center gap-3">
          {/* Don't uncomment me */}
          {/* <h4
              className="md:text-3xl text-2xl font-bold"
              style={{ color: appearanceSettings?.headingTextColour }}
            >
              How roof size affects cost
            </h4>
            <p>
              The average roof size{" "}
              {landingPageDetails?.state === "all"
                ? ""
                : `in ${getFullStateName(landingPageDetails?.state)}`}{" "}
              is between {formatNumber(landingPageDetails?.minAverageRoofSize)}{" "}
              - {formatNumber(landingPageDetails?.maxAverageRoofSize)} sq Feet.
              The size of your roof plays a significant role in determining the
              cost to replace it. Larger roofs require more materials and labor,
              which naturally increases the overall expense. A bigger roof means
              not only additional shingles, tiles, or metal panels but also
              extended underlayment, flashing, and fasteners to ensure a secure
              installation. Labor costs also rise as roof size increases since
              the job will take longer to complete. In addition, larger roofs
              may require more safety equipment and cleanup efforts, adding to
              the total cost. Therefore, when budgeting for a roof replacement,
              understanding your roof's size can help set realistic expectations
              for the project’s expense.
            </p> */}
          {/* Don't uncomment me ends*/}

          <div className="mt-2 flow-root">
            <div className="overflow-x-auto">
              <div className="inline-block min-w-full py-2 align-middle">
                <table className="min-w-full divide-y divide-gray-300 rounded-lg overflow-hidden">
                  <thead className="bg-gray-900 rounded-t-lg">
                    <tr className="text-white">
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-3 border-r border-gray-500"
                      >
                        System Size (kW)
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold sm:pl-3 border-r border-gray-500"
                      >
                        Average Cost
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold"
                      >
                        Cost After Tax Credit
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {roofData.map((data, index) => (
                      <tr
                        key={data.systemSizeInkW}
                        className={`even:bg-gray-50 ${
                          index === roofData.length - 1 ? "rounded-b-lg" : ""
                        }`}
                      >
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3 border-r border-gray-300">
                          {data.systemSizeInkW}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:pl-3 border-r border-gray-300">
                          {data.avgCost}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {data.costAfterTaxCredit}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* Don't uncomment me */}
          {/* <div>
              <button
                type="button"
                onClick={() => window.scrollTo(0, 0)}
                className="w-auto flex items-center gap-x-2 rounded-full px-4 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-400"
                style={{ background: appearanceSettings?.primaryColour }}
              >
                Calculate your roof area & see similar projects
                <FaCircleArrowRight className="w-5" />
              </button>
            </div> */}
          {/* Don't uncomment me ends*/}
        </div>
        <div className="flex flex-col justify-center gap-3 mt-6">
          <h4
            className="text-3xl font-bold"
            style={{ color: appearanceSettings?.headingTextColour }}
          >
            Important factors determine what size solar system you need.
          </h4>
          {/* Don't uncomment me */}
          {/* <p>
              While asphalt shingles are the most popular choice for homeowners
              due to their affordability, durability, and ease of installation,
              there are several other roofing options available. Each of these
              alternatives comes with its own unique advantages and
              considerations, allowing you to choose the best fit for your
              specific needs, style preferences, and budget.
            </p>

            <div className="mt-2 flow-root">
              <div className="overflow-x-auto">
                <div className="inline-block min-w-full py-2 align-middle">
                  <table className="min-w-full divide-y divide-gray-300 rounded-lg overflow-hidden">
                    <thead className="bg-gray-900 rounded-t-lg">
                      <tr className="text-white">
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-3 border-r border-gray-500"
                        >
                          Roof Material
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold"
                        >
                          Average Cost (for 2,500 Sq ft roof)
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {roofMaterial.map((data, index) => (
                        <tr
                          key={data.materialName}
                          className={`even:bg-gray-50 ${
                            index === roofData.length - 1 ? "rounded-b-lg" : ""
                          }`}
                        >
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3 border-r border-gray-300">
                            {data.materialName}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {data.avgCost}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div>
              <button
                type="button"
                onClick={() => window.scrollTo(0, 0)}
                className="w-auto flex items-center gap-x-2 rounded-full px-4 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-400"
                style={{ background: appearanceSettings?.primaryColour }}
              >
                Calculate your roof area & see similar projects
                <FaCircleArrowRight className="w-5" />
              </button>
            </div> */}
          {/* Don't uncomment me ende */}
        </div>
      </div>
      <div className="xl:mx-auto xl:max-w-7xl mt-6 mx-4">
        <div className="relative">
          <div className="md:float-right md:w-[45%] md:ml-4 mb-4 md:mb-0">
            <img
              src={
                landingPageDetails?.sectionImage
                  ? `${baseURL}s3/images/${landingPageDetails?.sectionImage}`
                  : ""
              }
              alt="Example"
              className="w-full rounded-lg object-cover"
            />
          </div>
          <div className="space-y-3">
            <h3
              className="text-2xl font-semibold"
              style={{ color: appearanceSettings?.headingTextColour }}
            >
              Location
            </h3>
            <p>
              The size of the solar system you need depends heavily on your
              location. Factors like average sunlight hours, climate, and
              regional energy costs influence how much energy your system must
              produce. For instance, areas with abundant sunlight typically
              require fewer panels to generate the same amount of electricity
              compared to cloudier regions or shaded roofs. Additionally, local
              weather patterns and seasonal variations may affect how
              efficiently your system performs, making it crucial to tailor the
              solar array to your specific geographical conditions.
            </p>
            <h3
              className="text-2xl font-semibold"
              style={{ color: appearanceSettings?.headingTextColour }}
            >
              Roof Orientation
            </h3>
            <p>
              South-facing roofs in the northern hemisphere typically receive
              the most sunlight throughout the day, allowing for optimal energy
              production with fewer panels. East- or west-facing roofs may
              require a larger system to produce the same amount of energy since
              they receive less direct sunlight. Similarly, if your roof is
              shaded by trees or nearby structures, you may need additional
              panels or a more powerful system to meet your energy needs
              effectively. Adjusting the system size based on roof orientation
              ensures maximum efficiency and cost savings.
            </p>
            <h3
              className="text-2xl font-semibold"
              style={{ color: appearanceSettings?.headingTextColour }}
            >
              Roof Pitch
            </h3>
            <p>
              A steep roof may limit the space available for panels, potentially
              requiring a more efficient system to meet energy needs.
              Conversely, a flat or low-pitched roof may require additional
              mounting equipment to angle the panels correctly, ensuring optimal
              energy production. By accounting for roof pitch, you can design a
              solar system that efficiently harnesses sunlight without
              oversizing.
            </p>
          </div>
        </div>
      </div>
      <div className="xl:mx-auto xl:max-w-7xl mt-10 mx-4">
        <div className="relative">
          <div className="space-y-3">
            <h3
              className="md:text-3xl text-2xl font-bold"
              style={{ color: appearanceSettings?.headingTextColour }}
            >
              Factors that determine the price of a solar system.
            </h3>
            <h3
              className="text-2xl font-semibold"
              style={{ color: appearanceSettings?.headingTextColour }}
            >
              Local & Federal Incentives
            </h3>
            <p>
              The Investment Tax Credit (ITC) and potential local incentives can
              significantly reduce the cost of your solar system. The ITC allows
              you to deduct a percentage of your system’s installation cost from
              your federal taxes, making solar more affordable upfront.
              Additionally, many states and local governments offer rebates, tax
              breaks, or performance-based incentives that can further lower
              your total investment. By combining the ITC with these local
              benefits, you can maximize your savings and achieve a faster
              return on investment.
              <br />
              You should consult a licences tax professional prior to purchasing
              a solar system to confirm your eligibility and using a
              knowledgeable local contractor can help you ensure you are aware
              of all the incentives available and how to claim them.
            </p>
            <h3
              className="text-2xl font-semibold"
              style={{ color: appearanceSettings?.headingTextColour }}
            >
              Inverter Type
            </h3>
            <p>
              The type of inverter you choose can influence both the price and
              performance of your solar system. String inverters are typically
              more cost-effective and suitable for simple installations, while
              microinverters, which are installed on each panel, provide greater
              flexibility and efficiency in systems with shading or varying
              panel orientations. Hybrid inverters, designed for battery storage
              integration, can add to the upfront cost but enhance long-term
              energy savings and resilience. Selecting the right inverter type
              ensures you get the best balance of affordability and performance
              tailored to your specific energy needs.
              <br /> A qualified solar contractor can help you decide which
              option suits your needs best.
            </p>
            <h3
              className="text-2xl font-semibold"
              style={{ color: appearanceSettings?.headingTextColour }}
            >
              Roof Area
            </h3>
            <p>
              The available roof area directly impacts the price of a solar
              system, as it determines how many panels can be installed and the
              system size needed to meet your energy goals. A larger roof area
              provides more flexibility to install enough panels efficiently,
              potentially lowering costs per watt. However, smaller or
              irregularly shaped roofs may require more advanced panel
              technology or customized designs, which can increase the price.
              Maximizing your roof's usable space ensures an optimal balance
              between cost and energy production.
            </p>
            <h3
              className="text-2xl font-semibold"
              style={{ color: appearanceSettings?.headingTextColour }}
            >
              Roof Material & Mounting System
            </h3>
            <p>
              The type of roof material and the mounting system required can
              significantly affect the price of a solar system. Standard
              materials like asphalt shingles are often more affordable for
              solar installations due to their compatibility with common
              mounting systems. In contrast, roofs made of tile, metal, or other
              specialized materials may require custom mounting solutions or
              additional labor, increasing installation costs.
            </p>
            <h3
              className="text-2xl font-semibold"
              style={{ color: appearanceSettings?.headingTextColour }}
            >
              Permitting Fees
            </h3>
            <p>
              Permitting and engineering fees are essential components of a
              solar system's total cost and can vary based on location and
              project complexity. Local governments typically require permits to
              ensure safety and compliance, and the fees for these can differ
              significantly by region. Additionally, customized engineering
              plans may be needed for unique roof designs or ground-mounted
              systems, which can add to upfront expenses. While these fees
              contribute to the overall price, they are crucial for a smooth
              installation process and long-term system reliability.
            </p>
            <div>
              <div className="my-4 grid gap-3 md:gap-6 grid-cols-8">
                <div className="col-span-5 h-72 md:h-[560px]">
                  <img
                    src={
                      landingPageDetails?.leftCollageImage
                        ? `${baseURL}s3/images/${landingPageDetails?.leftCollageImage}`
                        : ""
                    }
                    alt="Left"
                    className="w-full h-full object-cover rounded-lg"
                  />
                </div>
                <div className="col-span-3 grid grid-rows-2 gap-3 md:gap-6 h-72 md:h-[560px]">
                  <img
                    src={
                      landingPageDetails?.rightTopCollageImage
                        ? `${baseURL}s3/images/${landingPageDetails?.rightTopCollageImage}`
                        : ""
                    }
                    alt="Top Right"
                    className="w-full h-full object-cover rounded-lg"
                  />
                  <img
                    src={
                      landingPageDetails?.rightBottomCollageImage
                        ? `${baseURL}s3/images/${landingPageDetails?.rightBottomCollageImage}`
                        : ""
                    }
                    alt="Bottom Right"
                    className="w-full h-full object-cover rounded-lg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="xl:mx-auto xl:max-w-7xl mt-10 mx-4">
        <div className="space-y-3">
          <h3
            className="md:text-3xl text-2xl font-bold"
            style={{ color: appearanceSettings?.headingTextColour }}
          >
            Other important factors to consider:
          </h3>

          <h3
            className="text-2xl font-semibold"
            style={{ color: appearanceSettings?.headingTextColour }}
          >
            Installation quality
          </h3>
          <p>
            Installation quality is a critical factor when purchasing a solar
            system for your home, as it directly impacts the system’s
            performance, durability, and safety. Poorly installed panels or
            electrical components can lead to inefficiencies, potential damage,
            or even safety hazards over time. Choosing experienced and certified
            installers ensures that your system is correctly positioned,
            securely mounted, and properly wired for optimal energy production.
            High-quality installation also protects your investment by reducing
            maintenance needs and extending the lifespan of your solar system.
          </p>
          <h3
            className="text-2xl font-semibold"
            style={{ color: appearanceSettings?.headingTextColour }}
          >
            Balance of System
          </h3>
          <p>
            Contractors will often only talk about the major components of a
            solar system such as the panels and inverter/s. Components like
            cables, wiring, isolators, and conduit are essential to a safe and
            efficient solar system installation. High-quality cables and wiring
            ensure reliable energy transmission from the panels to the inverter
            and your home, while isolators allow for safe system shut-off during
            maintenance or emergencies. Properly installed conduit protects the
            wiring from weather and physical damage, ensuring long-term
            durability. Investing in top-tier components not only enhances
            system performance but also increases safety and reduces the risk of
            future repairs.
          </p>
          <h3
            className="text-2xl font-semibold"
            style={{ color: appearanceSettings?.headingTextColour }}
          >
            Warranties
          </h3>
          <p>
            When investing in a solar system, it's important to understand the
            warranties offered for the panels, inverters, and installation.
            Solar panels typically come with warranties ranging from 20 to 25
            years, ensuring they continue to perform at high efficiency over
            time. Inverters, which convert the energy produced by the panels
            into usable electricity, generally have shorter warranties, usually
            between 5 to 15 years, as they are more prone to wear and tear.
            <br />
            <br /> In addition to the equipment warranties, many installers
            offer workmanship warranties that cover the quality of the
            installation for 5 to 10 years. These warranties provide peace of
            mind, ensuring that any issues arising from improper installation
            are addressed without additional cost to you. Choosing a reputable
            installer with strong warranty offerings helps protect your
            investment and ensures your solar system will deliver reliable,
            long-term performance.
          </p>
        </div>
      </div>
      <div className="xl:mx-auto xl:max-w-7xl mt-10 mx-4 py-2 md:py-5 lg:py-10">
        <div className="flex justify-between">
          <h2
            className="md:text-3xl text-2xl font-bold mb-3"
            style={{ color: appearanceSettings?.headingTextColour }}
          >
            The Services We Provide
          </h2>
        </div>

        <div className="overflow-hidden mt-5 slider-container">
          
            <Slider {...settings}>
              {services?.map((service, index) => (
                <div key={index} className="flex justify-center">
                  <div className="mx-2 lg:col-span-4 md:col-span-6 col-span-12 h-full flex">
                    <div
                      className="rounded-lg bg-gradient-to-t from-sky-600 via-transparent to-transparent p-[3px] h-full w-full"
                      style={{
                        boxShadow: "0px 4px 34px 0px #0000000A",
                        background: `linear-gradient(to top, ${appearanceSettings?.secondaryColour}, transparent 50%)`,
                      }}
                    >
                      <div
                        className="w-full rounded-lg relative bg-white flex flex-col h-full min-h-[400px]"
                        style={{
                          boxShadow: "0px 4px 34px 0px #0000000A",
                        }}
                      >
                        <div className="w-full h-48 flex-shrink-0">
                          {" "}
                          <img
                            className="w-full h-full object-cover rounded-t-lg"
                            src={
                              service?.serviceId?.name === "Roofing"
                                ? RoofingCardImage
                                : RoofArea
                            }
                            alt={service?.serviceId?.name}
                          />
                        </div>
                        <div className="px-4 py-2">
                          <h5 className="text-lg font-bold text-gray-900">
                            {service?.serviceId?.name}
                          </h5>
                        </div>
                        <div className="px-4 py-2 flex flex-col items-start space-y-2 flex-grow">
                          <ul className="list-disc ml-5">
                            {service?.workTypes?.map((workType) => (
                              <li
                                key={workType?._id}
                                className="text-base text-gray-700"
                              >
                                {workType?.name}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
        </div>
      </div>
      <div
        className="max-w-7xl mx-auto py-3 md:py-5 lg:py-10"
        id="logos"
        
      >
        <h2 className="text-3xl text-center font-semibold mb-3">
          Trusted Brands
        </h2>
        <div className="overflow-hidden mt-5 slider-container">
          
            <Slider {...logosSettings}>
              {brandsOffered?.map((brand, index) => (
                <div key={index} className="flex justify-center items-center">
                  {" "}
                  <div className="mx-4 flex items-center justify-center">
                    <div className="p-4 rounded-lg transform transition-transform hover:scale-105 flex items-center justify-center">
                      <div className="w-auto max-h-28 flex items-center justify-center">
                        {brand?.logo ? (
                          <img
                            className="object-contain max-h-28 max-w-full"
                            src={`${baseURL}s3/images/${brand?.logo}`}
                            alt={brand?.name}
                          />
                        ) : (
                          <div className="w-24 h-24 bg-gray-200 rounded-full flex items-center justify-center">
                            <span className="text-xl font-bold text-gray-500">
                              {brand?.name
                                ? brand.name
                                    .split(" ")
                                    .map((word) => word[0])
                                    .join("")
                                    .toUpperCase()
                                : "N/A"}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
        </div>
      </div>
      <footer style={{ backgroundColor: appearanceSettings?.headerBGColour }}>
        <div className="mx-auto max-w-7xl px-6 py-12 flex flex-col lg:flex-row justify-between items-center lg:px-8 gap-x-4">
          <div className="order-2 lg:order-2 flex flex-col items-center lg:items-start space-y-4">
            <div className="bg-white px-2 py-1 rounded-md flex items-center">
              <p className="px-2 lg:px-3 text-gray-900 text-sm font-medium">
                Powered by
              </p>
              <img src={logo} alt="Logo" className="h-6" />
            </div>
            <div className="flex space-x-4">
              <Link
                to="/terms-and-conditions"
                className="text-sm font-light hover:underline text-white"
              >
                Terms and Conditions
              </Link>
              <Link
                to="/privacy-policy"
                className="text-sm font-light hover:underline text-white"
              >
                Privacy Policy
              </Link>
            </div>
          </div>

          <a href="#" className="order-1 lg:order-1">
            <span className="sr-only">{company?.name}</span>
            <img
              alt={company?.name || "Company Logo"}
              src={company?.logo ? `${baseURL}s3/images/${company?.logo}` : ""}
              className="h-16 w-auto"
            />
          </a>
        </div>

        <hr className="border-gray-500" />

        <div className="py-4 text-center">
          <span className="font-light text-sm text-white">
            © 2024 Pro-Mapper. All rights reserved.
          </span>
        </div>
      </footer>
    </>
  );
};

export default SolarLandingPage;
