import axios from "axios";

export const baseURL = process.env.REACT_APP_API_URL;

export const API = axios.create({
  baseURL: baseURL,
});

API.interceptors.request.use((req) => {
  const profile =
    localStorage.getItem("admin") ||
    localStorage.getItem("company") ||
    localStorage.getItem("brand");
  if (profile) {
    const token = JSON.parse(profile).token;
    req.headers.authorization = `Bearer ${token}`;
  }
  return req;
});

//Auth

export const signin = (formData) => API.post("/users/signin", formData);

export const getUserLocation = () =>
  API.post(
    `https://www.googleapis.com/geolocation/v1/geolocate?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
    {}
  );

//Services

export const getServices = () => API.get("/services");

export const getServiceFilters = (id) => API.get(`/services/filters/${id}`);

export const createService = (newService) => API.post("/services", newService);

export const updateService = (id, updatedService) =>
  API.patch(`/services/${id}`, updatedService);

export const deleteService = (id) => API.delete(`/services/${id}`);

//Projects

export const getProjects = () => API.get("/projects");

export const getServicesByUserId = (id) => API.get(`/users/${id}/services`);

export const getProjectsByBounds = (bounds) =>
  API.post("/projects/bounds", { bounds });

export const createProject = (newProject) => API.post("/projects", newProject);

export const uploadProjectsCsv = (newData) =>
  API.post("/projects/uploadCsv", newData);

export const submitResolvedBrandConflicts = (newData) =>
  API.post("/equipmentBrands/addConflictedBrands", newData);

export const updateProject = (id, updatedProject) =>
  API.patch(`/projects/${id}`, updatedProject);

export const deleteProject = (id) => API.delete(`/projects/${id}`);

//EquipmentTypes

export const getEquipmentTypes = () => API.get("/equipmentTypes");

export const createEquipmentType = (newEquipmentType) =>
  API.post("/equipmentTypes", newEquipmentType);

export const updateEquipmentType = (id, updatedEquipmentType) =>
  API.patch(`/equipmentTypes/${id}`, updatedEquipmentType);

export const deleteEquipmentType = (id) => API.delete(`/equipmentTypes/${id}`);

//EquipmentModels

export const getEquipmentModels = () => API.get("/equipmentModels");

export const createEquipmentModel = (newEquipmentModel) =>
  API.post("/equipmentModels", newEquipmentModel);

export const updateEquipmentModel = (id, updatedEquipmentModel) =>
  API.patch(`/equipmentModels/${id}`, updatedEquipmentModel);

export const deleteEquipmentModel = (id) =>
  API.delete(`/equipmentModels/${id}`);

//EquipmentBrands

export const getEquipmentBrands = () => API.get("/equipmentBrands");

export const getEquipmentBrandById = (id) =>
  API.get(`/equipmentBrands/id/${id}`);

export const createEquipmentBrand = (newEquipmentBrand) =>
  API.post("/equipmentBrands", newEquipmentBrand);

export const uploadEquipmentBrandsCsv = (newEquipmentBrands) =>
  API.post("/equipmentBrands/upload", newEquipmentBrands);

export const updateEquipmentBrand = (id, updatedEquipmentBrand) =>
  API.patch(`/equipmentBrands/${id}`, updatedEquipmentBrand);

export const deleteEquipmentBrand = (id) =>
  API.delete(`/equipmentBrands/${id}`);

//WorkTypes

export const getWorkTypes = () => API.get("/workTypes");

export const createWorkType = (newWorkType) =>
  API.post("/workTypes", newWorkType);

export const updateWorkType = (id, updatedWorkType) =>
  API.patch(`/workTypes/${id}`, updatedWorkType);

export const deleteWorkType = (id) => API.delete(`/workTypes/${id}`);

//Contacts

export const getContacts = () => API.get("/contacts");

export const createContact = (newContact) => API.post("/contacts", newContact);

export const updateContact = (id, updatedContact) =>
  API.patch(`/contacts/${id}`, updatedContact);

export const deleteContact = (id) => API.delete(`/contacts/${id}`);

//Offices

export const getOffices = () => API.get("/offices");

export const getOfficeById = (id) => API.get(`/offices/${id}`);

export const createOffice = (newOffice) => API.post("/offices", newOffice);

export const updateOffice = (id, updatedOffice) =>
  API.patch(`/offices/${id}`, updatedOffice);

export const deleteOffice = (id) => API.delete(`/offices/${id}`);

//Users

export const getUsers = () => API.get("/users");

export const getUserById = (id) => API.get(`/users/${id}`);

export const createUser = (newUser) => API.post("/users", newUser);

export const updateUser = (id, updatedUser) =>
  API.patch(`/users/${id}`, updatedUser);

export const deleteUser = (id) => API.delete(`/users/${id}`);

//Roles

export const getRoles = () => API.get("/roles");

export const createRole = (newRole) => API.post("/roles", newRole);

export const updateRole = (id, updatedRole) =>
  API.patch(`/roles/${id}`, updatedRole);

export const deleteRole = (id) => API.delete(`/roles/${id}`);

//OptionSets

export const getOptionSets = () => API.get("/optionSets");

export const createOptionSet = (newOptionSet) =>
  API.post("/optionSets", newOptionSet);

export const updateOptionSet = (id, updatedOptionSet) =>
  API.patch(`/optionSets/${id}`, updatedOptionSet);

export const deleteOptionSet = (id) => API.delete(`/optionSets/${id}`);

//Companies

export const getCompanies = () => API.get("/companies");

export const getCompanyById = (id) => API.get(`/companies/${id}`);

export const getServicesByCompanyId = (id) =>
  API.get(`/companies/${id}/services`);

export const getLandingPageServicesByCompanyId = (id) =>
  API.get(`/companies/${id}/landingPage/services`);

export const createCompany = (newCompany) => API.post("/companies", newCompany);

export const updateCompany = (id, updatedCompany) =>
  API.patch(`/companies/${id}`, updatedCompany);

export const deleteCompany = (id) => API.delete(`/companies/${id}`);

// Dynamic Fields

export const getDynamicOptions = (module) => API.get(`/${module}s`);

export const getProjectsCreatedByUser = (id, filter) => {
  return API.post(`/projects/createdBy/${id}`, filter);
};

export const getProjectsCreatedByCompany = (id, filter) => {
  return API.post(`/projects/createdBy/company/${id}`, filter);
};

export const getProjectsUsingEquipmentBrand = (id, filter) => {
  return API.post(`/projects/using/equipmentBrand/${id}`, filter);
};

export const getAllFilters = (serviceId) => {
  return API.get(`/services/${serviceId}/filters`);
};

export const getProjectById = (id) => API.get(`/projects/id/${id}`);

export const getFilterPresetById = (filterId) =>
  API.get(`/filterPresets/${filterId}`);

export const getMapPageById = (filterId) => API.get(`/mapPages/${filterId}`);

export const getRoofingLandingPageById = (filterId) =>
  API.get(`/landingPages/roofing/${filterId}`);

export const getSolarLandingPageById = (filterId) =>
  API.get(`/landingPages/solar/${filterId}`);

export const getBrandsOfferedByCompany = (companyId) =>
  API.get(`/companies/${companyId}/brandsOffered`);

export const contactRequests = (contact) =>
  API.post("/contactRequests/", contact);

export const getOfficesByBounds = (companyId, bounds) =>
  API.post(`/offices/company/${companyId}/bounds`, { bounds });

export const createPartnersPageResponse = (userId, response) =>
  API.post(`/partners/${userId}`, response);

export const storePartnersPageResopnseInDatabase = (companyId, response) =>
  API.post(`/partners/store/${companyId}`, response);

export const updatePartnersPageResponseStep = (id, data) => {
  console.log("ID: ", id);
  return API.put(`/partners/update-step/${id}`, data);
};

export const updateContactAndZipStatus = (id, data) =>
  API.put(`/partners/${id}/update-contact-and-zip-status`, data);

export const sendMapIframeStatistics = (data) =>
  API.post("/mapIframeStatistics", data);

export const updateMapIframeStatistics = (id, data) =>
  API.patch(`/mapIframeStatistics/${id}`, data);

export const getRoofTypes = () =>
  API.get(`/optionSets/${process.env.REACT_APP_ROOF_TYPES_OPTION_SET_ID}`);

export const getBuildingRoofSizeAndImage = (lat, lng) =>
  API.get(`/mapPages/roofing/buildingSizeAndImage?lat=${lat}&lng=${lng}`);

export const getSolarSystemSizeAndImage = (lat, lng, avgElectricBill) =>
  API.get(
    `/landingPages/solar/buildingSizeAndImage?lat=${lat}&lng=${lng}&avgElectricBill=${avgElectricBill}`
  );

export const getRelevantProjects = ({
  serviceId,
  roofType,
  propertyType,
  estimatedRoofArea,
  companyId,
  state,
}) =>
  API.get(`/projects/relevant/summary`, {
    params: {
      serviceId,
      roofType,
      propertyType,
      estimatedRoofArea,
      companyId,
      state,
    },
  });

export const getRelevantSolarProjects = ({
  serviceId,
  workTypes,
  propertyType,
  systemSizeMinRange,
  systemSizeMaxRange,
  companyId,
  state,
  loanRate,
  loanTerm,
}) =>
  API.get(`/projects/relevant/solar/summary`, {
    params: {
      serviceId,
      workTypes,
      propertyType,
      systemSizeMinRange,
      systemSizeMaxRange,
      companyId,
      state,
      loanRate,
      loanTerm,
    },
  });
